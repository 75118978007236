import { reactive } from "vue";
export default function(list, options, field) {
  let allList = reactive(list);
  let enumeration = allList.find(v => v.field === field);
  enumeration.options.length = 0;
  for (let item of options) {
    enumeration.options.push(item);
  }
  return allList;
}
