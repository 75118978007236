/**
 * 搜索头
 */
export const searchHeaderOptions = [
  {
    field: "company_id",
    label: "企业名称",
    type: "select-data-search",
    defaultValue: null,
    iw: 160,
    props: {
      placeholder: "请输入企业名称"
    },
    options: []
  },
  {
    field: ["register_start_time", "register_end_time"],
    iw: 324,
    label: "入驻时间",
    type: "dateRange",
    props: {
      showTime: true,
      format: "YYYY/MM/DD"
    }
  }
];

export const schemaList = [
  {
    type: "select",
    field: "direction",
    label: "收支方向",
    iw: 160,
    defaultValue: null,
    options: []
  },
  {
    field: "service_group",
    label: "调用服务",
    type: "select",
    iw: 200,
    options: []
  },
  {
    field: ["start_time", "end_time"],
    iw: 324,
    label: "操作时间",
    type: "dateRange",
    props: {
      placeholder: ["开始时间", "结束时间"],
      showTime: true,
      format: "YYYY/MM/DD"
    }
  }
];
