<template>
  <transition leave-active-class="animate__animated animate__fadeOut">
    <div class="mask" v-show="visible" @click.self="handleClose">
      <transition
        enter-active-class="animate__animated animate__fadeInUp"
        leave-active-class="animate__animated animate__fadeOutDown"
      >
        <div
          v-if="visible"
          class="drawer"
          :style="{ minHeight: height + 'px' }"
        >
          <Icon
            class="icon-close"
            name="iconguanbi"
            width="20"
            height="20"
            @click="handleClose"
          ></Icon>
          <slot></slot>
        </div>
      </transition>
    </div>
  </transition>
</template>
<script>
export default {
  name: "Drawer",
  props: {
    height: { type: String, default: "400" },
    width: { type: String, default: "14" },
    visible: { type: Boolean, default: false }
  },
  setup(props, ctx) {
    const handleClose = () => {
      ctx.emit("update:visible", false);
    };
    return { handleClose };
  }
};
</script>
<style lang="less" scoped>
.mask {
  height: 100vh;
  width: calc(~"100% - 200px");
  background: rgba(0, 0, 0, 0.45);
  position: fixed;
  left: 200px;
  top: 0;
  z-index: 99;
  .drawer {
    background: #fff;
    box-shadow: 0 -2px 8px rgb(0 0 0 0.15);
    z-index: 1;
    overflow: auto;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 16px 24px;
    max-height: 100%;
    .icon-close {
      position: absolute;
      right: 16px;
      top: 16px;
      cursor: pointer;
    }
  }

  .animate__animated.animate__fadeInUp {
    --animate-duration: 0.3s;
  }
  .animate__animated.animate__fadeOutDown {
    --animate-duration: 0.3s;
  }
  .animate__animated.animate__zoomOut {
    --animate-duration: 0.1s;
  }
}
</style>
<style lang="less"></style>
