import { buildApi } from "@/utils/ajax";

const basicApi = buildApi("/portal");

// 收支方向
export const useEnumAccountDirectionApi = basicApi({
  url: "/account/enum/direction",
  withCredentials: true
});

// 服务
export const useEnumServiceApi = basicApi({
  url: "/account/enum/account_service",
  withCredentials: true
});

// 账户类型
export const useEnumAccountTypeApi = basicApi({
  url: "/account/enum/account_type",
  withCredentials: true
});

// 企业列表枚举
export const useCompanyEnumApi = basicApi({
  url: "/ops/company/list",
  withCredentials: true
});

// 司机姓名列表枚举
export const useDriverListEnumApi = basicApi({
  url: "/ops/driver/list",
  withCredentials: true
});

// 服务商枚举
export const useServiceProvideListEnumApi = basicApi({
  url: "/common/enum/service_provide_list",
  withCredentials: true
});

// 油卡类型枚举
export const useGasCardTypeListEnumApi = basicApi({
  url: "/common/enum/gas_card_type_list",
  withCredentials: true
});

// 数据字典枚举数组
export const useDictListEnumApi = basicApi({
  url: "/common/enum/dict_list",
  withCredentials: true
});

// 数据字典枚举单个数据
export const useDictEnumApi = basicApi({
  url: "/common/enum/dict",
  withCredentials: true
});

// 提现状态
export const useWithdrawStatusEnumApi = basicApi({
  url: "/account/enum/withdraw_status",
  withCredentials: true
});
