<template>
  <div>
    <Modal
      class="xModal-box"
      :title="title"
      :visible="modalVisible"
      :width="w"
      :closable="closable"
      :footer="footer"
      @cancel="handleCancel"
      @ok="handleOk"
      :destroyOnClose="true"
    >
      <template #closeIcon>
        <Icon name="iconguanbi" height="18" width="18"></Icon>
      </template>
      <slot name="content"></slot>
    </Modal>
  </div>
</template>
<script>
import { computed } from "vue";
import { Modal } from "ant-design-vue";
export default {
  name: "XModal",
  components: { Modal },
  props: {
    title: { type: String, default: "" },
    footer: { type: String },
    visible: { type: Boolean, default: false },
    maskClosable: { type: Boolean, default: true },
    w: { type: Number, default: 400 },
    closable: { type: Boolean, default: true }
  },
  setup(props, ctx) {
    const modalVisible = computed(() => props.visible);
    // 确定
    const handleOk = () => {
      ctx.emit("handleOk");
    };
    // 取消
    const handleCancel = () => {
      ctx.emit("handleCancel", false);
    };
    return { modalVisible, handleOk, handleCancel };
  }
};
</script>
<style lang="less">
.xModal-box {
  .ant-modal-title {
    font-size: 14px;
  }
  .ant-modal-body {
    padding: 40px 48px 16px;
  }
  .ant-modal-footer {
    padding: 24px 16px;
    border: none;
    display: flex;
    justify-content: center;
  }
  .ant-modal-footer button + button {
    margin-left: 16px !important;
  }
}
</style>
