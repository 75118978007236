<template>
  <div>
    <xModal
      title="详情"
      :visible="visible"
      :w="817"
      :footer="null"
      @handleCancel="handleCancel"
    >
      <template #content>
        <ul class="info-list">
          <li class="info">
            <p class="label">企业名称：</p>
            <p class="value w-310">
              {{ info.name }}
            </p>
          </li>
          <li class="info">
            <p class="label">企业税号：</p>
            <p class="value">{{ info.tax_number }}</p>
          </li>
          <li class="info">
            <p class="label">开户银行：</p>
            <p class="value w-310">
              {{ info.buyer_bank }}
            </p>
          </li>
          <li class="info">
            <p class="label">银行账号：</p>
            <p class="value">{{ info.buyer_bank_account }}</p>
          </li>
          <li class="info address">
            <p class="label">企业地址：</p>
            <p class="value ">
              {{ info.buyer_address }}
            </p>
          </li>
          <li class="info">
            <p class="label">法人姓名：</p>
            <p class="value w-310">{{ info.legal_name }}</p>
          </li>
          <li class="info">
            <p class="label">法人身份证号：</p>
            <p class="value">{{ info.legal_id_no }}</p>
          </li>
          <li class="info">
            <p class="label">单位电话：</p>
            <p class="value w-310">{{ info.contact_phone }}</p>
          </li>
          <li class="info address">
            <p class="label">营业执照：</p>
            <p class="value ">
              <Image
                class="img"
                :src="
                  info.business_license?.data ||
                    'https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/vas/admin/public/business-license.png'
                "
              />
            </p>
          </li>
          <li class="info address">
            <p class="label">法人身份证照片：</p>
            <p class="value ">
              <Image
                class="img"
                :src="
                  info.id_card_head?.data ||
                    'https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/vas/admin/public/idcard-front.png'
                "
              />
              <Image
                class="img"
                :src="
                  info.id_card_national_emblem?.data ||
                    'https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/vas/admin/public/idcard-back.png'
                "
              />
            </p>
          </li>
        </ul>
        <a-button class="submit-btn" type="primary" @click="handleCancel"
          >确定</a-button
        >
      </template>
    </xModal>
  </div>
</template>

<script>
import { Image } from "ant-design-vue";
import xModal from "@/components/xModal";
import { useQueryCompanyDetailApi } from "@/apis/customer";
import $await from "@/utils/await";
import { watch, ref } from "vue";

export default {
  props: {
    id: { type: String, default: "" },
    visible: { type: Boolean, default: false }
  },
  components: {
    xModal,
    Image
  },
  setup(props, ctx) {
    const queryCompanyDetailApi = useQueryCompanyDetailApi();

    const info = ref({});

    watch(
      () => props.id,
      async val => {
        if (val && props.visible) {
          let [, res] = await $await(
            queryCompanyDetailApi({ company_id: props.id })
          );
          info.value = res || {};
        }
      }
    );

    // 取消
    const handleCancel = () => {
      ctx.emit("cancel");
    };

    return {
      info,
      handleCancel
    };
  }
};
</script>

<style lang="less" scoped>
.info-list {
  overflow: hidden;
  .info {
    float: left;
    display: flex;
    margin-bottom: 24px;
    .label {
      text-align: right;
      width: 112px;
      color: #808080;
      display: table;
    }
    .w-310 {
      width: 310px;
    }
  }
  .address {
    width: 100%;
    .value {
      flex: 1;
    }
  }
}
.submit-btn {
  margin: 0 auto;
  display: block;
}
</style>
<style lang="less">
.info-list {
  .img {
    height: 120px;
    width: 180px;
    margin-right: 16px;
  }
}
</style>
