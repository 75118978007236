<template>
  <div>
    <div>
      <span class="mr-30">企业名称: {{ info.name }}</span>
      <span>统一信用代码: {{ info.tax_number }}</span>
    </div>
    <div class="mt-14 pb-16">
      <RadioGroup :value="type" @change="handleType">
        <RadioButton value="GENERIC">
          通用账户
        </RadioButton>
        <RadioButton value="DEPOSIT">
          储值账户
        </RadioButton>
        <RadioButton value="CONSUMER">
          消费卡
        </RadioButton>
      </RadioGroup>
    </div>
    <div v-if="type === 'GENERIC'">
      <p-table :dataSource="tableDataSource" id="service-configuration-table1">
        <p-t-column type="seq" width="60" title="序号" fixed="left" />
        <p-t-column title="账号" field="card_no" />
        <p-t-column title="账户类型">通用账户</p-t-column>
        <p-t-column title="余额（元）" field="balance" align="right">
          <template v-slot="{ row }">
            {{ row.balance && (row.balance / 100).toFixed(2) }}
          </template>
        </p-t-column>
        <p-t-column width="172" title="操作" fixed="right">
          <template v-slot="{ row }">
            <a-button type="link" @click="handleJump(info, '通用账户', row)"
              >账户明细
            </a-button>
          </template>
        </p-t-column>
      </p-table>
    </div>
    <div v-if="type === 'DEPOSIT'">
      <p-table :dataSource="tableDataSource" id="service-configuration-table2">
        <p-t-column type="seq" width="60" title="序号" fixed="left" />
        <p-t-column title="账号" field="card_no" />
        <p-t-column title="账户类型">储值账户</p-t-column>
        <p-t-column title="服务项" field="account_service_desc" />
        <p-t-column title="余额（元）" field="balance" align="right">
          <template v-slot="{ row }">
            {{ row.balance && (row.balance / 100).toFixed(2) }}
          </template>
        </p-t-column>
        <p-t-column width="172" title="操作" fixed="right">
          <template v-slot="{ row }">
            <a-button type="link" @click="handleJump(info, '储值账户', row)"
              >账户明细
            </a-button>
          </template>
        </p-t-column>
      </p-table>
    </div>
    <div v-if="type === 'CONSUMER'">
      <p-table :dataSource="tableDataSource" id="service-configuration-table3">
        <p-t-column type="seq" min-width="60" title="序号" fixed="left" />
        <p-t-column title="账号" field="card_no" min-width="240" />
        <p-t-column title="账户类型" min-width="120">消费卡</p-t-column>
        <p-t-column
          title="服务项"
          field="account_service_desc"
          min-width="180"
        />
        <p-t-column
          title="余额（元）"
          field="balance"
          min-width="120"
          align="right"
        >
          <template v-slot="{ row }">
            {{ row.balance && (row.balance / 100).toFixed(2) }}
          </template>
        </p-t-column>
        <p-t-column title="生效时间" field="valid_start" min-width="230">
          <template v-slot="{ row }">
            {{
              row.valid_start &&
                formatToDateTime(row.valid_start, "yyyy-MM-dd hh:mm:ss")
            }}
          </template>
        </p-t-column>
        <p-t-column title="失效时间" field="valid_end" min-width="230">
          <template v-slot="{ row }">
            {{
              row.valid_end &&
                formatToDateTime(row.valid_end, "yyyy-MM-dd hh:mm:ss")
            }}
          </template>
        </p-t-column>
        <p-t-column title="状态" field="status_desc" min-width="120" />
        <p-t-column width="172" title="操作" fixed="right">
          <template v-slot="{ row }">
            <a-button type="link" @click="handleJump(info, '消费卡', row)">
              账户明细
            </a-button>
          </template>
        </p-t-column>
      </p-table>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted, watch } from "vue";
import { Radio } from "ant-design-vue";
import { Table } from "@wlhy-web-components/common";
import { useCompanyAccountApi } from "@/apis/customer";
import { formatToDateTime } from "@wlhy-web-lib/shared";
import { useRouter } from "vue-router";

export default {
  name: "service-configuration",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button
  },
  setup(props) {
    const router = useRouter();
    const type = ref("GENERIC");
    const tableDataSource = reactive({
      loading: false,
      data: [],
      error: "",
      meta: {
        total: 0,
        pageSize: 20,
        currentPageNo: 1
      },
      sum: {
        data: [],
        loading: false,
        error: ""
      }
    });
    watch(props.info, () => {
      refreshTable();
    });

    const companyAccountApi = useCompanyAccountApi();
    const handleType = e => {
      type.value = e.target.value;
      refreshTable();
    };
    const refreshTable = Table.useTableConfig({
      dataSource: tableDataSource,
      async fetchData() {
        const data = {
          company_id: props.info.company_id,
          account_type: type.value,
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo
        };
        const res = await companyAccountApi(data);
        return { records: res.list, total: res.total };
      }
    });

    onMounted(() => {
      refreshTable();
    });
    const handleJump = (info, type, row) => {
      const { company_id, tax_number, name } = info;
      const { id } = row;
      router.push({
        path: "/customer/enterprise-detail",
        query: {
          name,
          company_id,
          tax_number,
          account_id: id,
          type
        }
      });
    };

    return {
      tableDataSource,
      type,
      handleType,
      formatToDateTime,
      router,
      handleJump
    };
  }
};
</script>

<style lang="less" scoped></style>
