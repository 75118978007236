import { ref } from "vue";
import { Searcher } from "@wlhy-web-components/common";

export default function useSearchForm(schemaData) {
  // 初始化filter 否则useTableConfig里面监听不到
  const searchForm = Searcher.getDefaultValues(schemaData);
  const filter = ref(searchForm);

  // 表格查询
  const handleFormSearch = newFilter => {
    filter.value = newFilter;
    filter.value.click = true;
  };
  return {
    filter,
    handleFormSearch
  };
}
