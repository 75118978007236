export default function(res) {
  let arr = JSON.parse(JSON.stringify(res));

  let json = {};
  arr.map(item => {
    let key = item["code"];

    let dictJson = {};

    if (item.dict_info_responses.length) {
      item.dict_info_responses.map(dict => {
        let key1 = dict["code"];
        dictJson[key1] = dict.name;
        json[key] = dictJson;
      });
    }
  });
  return json;
}
