<template>
  <div class="page-main-box pt-24 pr-16" ref="serviceOrderBox" id="company">
    <div class="pl-16">
      <!-- 搜索头 -->
      <search-header :schemaData="schema" @getDataList="handleFormSearch" />

      <!-- 列表 -->
      <div :style="{ height: tableHeight + 'px' }">
        <p-table
          :dataSource="tableDataSource"
          id="service-configuration-table"
          @sort-change="sortChangeEvent"
          height="auto"
        >
          <p-t-column type="seq" width="60" title="序号" fixed="left" />
          <p-t-column min-width="166" title="企业名称" field="name" />
          <p-t-column min-width="236" title="统一信用代码" field="tax_number" />
          <p-t-column min-width="166" title="入驻产业园">
            <template v-slot="{ row }">
              {{
                row.industrial_park
                  ? dictList.INDUSTRIAL_PARK[row.industrial_park]
                  : "-"
              }}
            </template>
          </p-t-column>
          <p-t-column title="入驻时间" field="register_time" min-width="183">
            <template v-slot="{ row }">
              {{
                row.register_time &&
                  formatToDateTime(row.register_time, "yyyy-MM-dd hh:mm:ss")
              }}
            </template>
          </p-t-column>
          <p-t-column min-width="140" title="联系人" field="contact" />
          <p-t-column min-width="140" title="手机号" field="contact_phone" />
          <p-t-column min-width="140" title="创建人" field="creator" />
          <p-t-column width="170" title="操作" fixed="right">
            <template v-slot="{ row }">
              <a-button type="link" @click="handleCompony(row)">
                企业账户
              </a-button>
              <a-button
                v-if="$store.getters.roles.includes('EnterpriseDeploy')"
                type="link"
                @click="handleConfig(row)"
              >
                配置
              </a-button>
              <a-button type="link" @click="handleDetail(row)">
                详情
              </a-button>
            </template>
          </p-t-column>
        </p-table>
      </div>

      <!-- 企业账户查看 -->
      <Drawer
        class="drawer-box"
        @close="showDrawer = false"
        v-model:visible="showDrawer"
        height="560"
      >
        <ServiceProvider v-if="showDrawer" :info="companyInfo" />
      </Drawer>

      <!-- 企业详情 -->
      <CompanyInfo
        :visible="showDetail"
        :id="companyId"
        @cancel="showDetail = false"
      ></CompanyInfo>

      <!-- 配置修改框 -->
      <xModal
        :w="423"
        title="配置"
        :visible="configModal"
        :footer="null"
        @handleCancel="configModal = false"
      >
        <template #content>
          <ul class="switch-list">
            <li v-for="item in formStateConfig" :key="item.id">
              <span class="label">{{
                dictList.company_service[item.service_code]
              }}</span
              ><Switch
                v-model:checked="item.status"
                @change="handleSwitchChange($event, item.service_code)"
              />
            </li>
          </ul>
        </template>
      </xModal>
    </div>
  </div>
</template>

<script>
import { reactive, ref, toRaw, onMounted } from "vue";
import { Switch } from "ant-design-vue";
import { Table } from "@wlhy-web-components/common";
import { searchHeaderOptions } from "./Constants";
import xModal from "@/components/xModal";
import ServiceProvider from "./components/ServiceProvider/index.vue";
import CompanyInfo from "./components/CompanyDetail/index.vue";
import useSearchForm from "@/hooks/UseSearchForm";
import { formatToDateTime } from "@wlhy-web-lib/shared";
import {
  useCompanyListApi,
  useCompanySubscriptionListApi,
  useCompanySubscriptionUpdateStatusApi
} from "@/apis/customer";
import { useCompanyEnumApi, useDictListEnumApi } from "@/apis/enums";
import Drawer from "@/components/Drawer";
import useSearch from "@/hooks/useSearch";
import useTableHeight from "@/hooks/useTableHeight";
import $await from "@/utils/await";
import useDebounce from "@/hooks/useDebounce";
import useDictJsonFormat from "@/hooks/useDictJsonFormat";

export default {
  name: "service-order",
  components: { ServiceProvider, Drawer, CompanyInfo, xModal, Switch },
  setup() {
    const companyListApi = useCompanyListApi();
    const companyEnumApi = useCompanyEnumApi();
    const dictListEnumApi = useDictListEnumApi();
    const companySubscriptionListApi = useCompanySubscriptionListApi();
    const companySubscriptionUpdateStatusApi = useCompanySubscriptionUpdateStatusApi();

    const tableHeight = ref(null);
    const dictList = ref({});

    const handleSearch = useDebounce(async value => {
      const [, res] = await $await(
        companyEnumApi({ name: value, page: 1, size: 100 })
      );
      useSearch(searchHeaderOptions, res, "company_id", false);
    }, 500);

    handleSearch();

    const handleGetDickList = async () => {
      const [, res] = await $await(
        dictListEnumApi(["INDUSTRIAL_PARK", "company_service"])
      );
      if (res) {
        dictList.value = useDictJsonFormat(res);
        console.log(useDictJsonFormat(res));
      }
    };
    handleGetDickList();

    let enumeration = searchHeaderOptions.find(v => v.field === "company_id");
    enumeration.props.onSearch = handleSearch;

    const { filter, handleFormSearch } = useSearchForm(searchHeaderOptions);
    const showDrawer = ref(false);
    const showDetail = ref(false);
    const companyInfo = ref({});
    const countInfo = ref({});
    const tableDataSource = reactive({
      loading: false,
      data: [],
      error: "",
      meta: {
        total: 0,
        pageSize: 20,
        currentPageNo: 1
      },
      sum: {
        data: [],
        loading: false,
        error: ""
      }
    });

    const refresh = Table.useTableConfig({
      filter,
      dataSource: tableDataSource,
      async fetchData() {
        const data = {
          ...toRaw(filter.value),
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo
        };
        const res = await companyListApi(data);
        return { records: res.list, total: res.total };
      }
    });

    const handleCompony = item => {
      companyInfo.value = item;
      showDrawer.value = true;
    };

    const sortChangeEvent = colums => {
      const { order } = colums;
      filter.value.sort = "register_time " + order;
      refresh();
    };

    const companyId = ref("");

    // 查看企业信息详情
    const handleDetail = item => {
      companyId.value = item.company_id;
      showDetail.value = true;
    };

    // 配置数据
    const formStateConfig = ref({});

    // 配置弹窗
    const configModal = ref(false);

    // 修改配置
    const handleConfig = item => {
      console.log(item);
      handleGetConfig(item);
      configModal.value = true;
    };
    // 获取配置项
    const handleGetConfig = async item => {
      const [, res] = await $await(
        companySubscriptionListApi({ company_id: item.company_id })
      );
      res.map(item => {
        item.status === "APPROVED" ? true : false;
      });
      formStateConfig.value = res;

      companyId.value = item.company_id;
    };
    // 修改配置项
    const handleSwitchChange = async (checked, item) => {
      let data = {
        company_id: companyId.value,
        service_code: item,
        status: checked ? "APPROVED" : "REJECTED"
      };

      console.log(data);
      const [, res] = await $await(companySubscriptionUpdateStatusApi(data));
      if (!res) {
        formStateConfig.value.find(
          i => i.service_code === item
        ).status = !checked;
      }
    };

    onMounted(() => {
      tableHeight.value = useTableHeight();
    });

    return {
      companyId,
      formStateConfig,
      configModal,
      tableDataSource,
      handleFormSearch,
      schema: searchHeaderOptions,
      handleCompony,
      handleSwitchChange,
      handleDetail,
      handleConfig,
      showDrawer,
      companyInfo,
      showDetail,
      sortChangeEvent,
      refresh,
      countInfo,
      formatToDateTime,
      tableHeight,
      dictList
    };
  }
};
</script>

<style lang="less" scoped>
#company {
  position: relative;
  overflow: hidden;
}
:deep(.search-header .operator-left) {
  margin-left: 0;
}
</style>
<style lang="less">
.switch-list {
  width: 100%;
  li {
    width: 50%;
    display: inline-block;
    margin-bottom: 24px;
    .label {
      width: 56px;
      display: inline-block;
      margin-right: 16px;
      text-align: right;
    }
  }
}
</style>
