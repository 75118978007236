// import { post } from "@/utils/http";

// export const companyListApi = params => {
//   post("/portal/company/page", params);
// };

import { buildApi } from "@/utils/ajax";

const basicApi = buildApi("/portal/ops");

export const useCompanyListApi = basicApi({
  url: "/company/page",
  withCredentials: true
});

export const useCompanyAccountApi = basicApi({
  url: "/account/query",
  withCredentials: true
});

export const useDailyBillApi = basicApi({
  url: "/daily_bill/query",
  withCredentials: true
});

export const useExportAccountApi = basicApi({
  url: "/daily_bill/query",
  withCredentials: true,
  responseType: "blob",
  meta: { isTransformRequestResult: false }
});

export const useQueryCompanyDetailApi = basicApi({
  url: "/company/query_company_detail",
  withCredentials: true
});

// 企业订阅服务列表
export const useCompanySubscriptionListApi = basicApi({
  url: "/company_subscription/list",
  withCredentials: true
});
// 更新企业订阅服务状态
export const useCompanySubscriptionUpdateStatusApi = basicApi({
  url: "/company_subscription/update_status",
  withCredentials: true
});
